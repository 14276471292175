.post-images-swiper {
  .swiper-pagination-bullet {
    background: white;
    width: 10px;
    height: 10px;
  }

  @media (max-width: 900px) {
    .swiper-pagination-bullet {
      width: 4px;
      height: 4px;
    }
  }

  .post-button-prev {
    padding: 10px;
    background: #00000099;
  }
  .swiper-button-prev,
  .swiper-button-next {
    background: #00000099;
    border-radius: 50%;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    content: '';
  }
}
