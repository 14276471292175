.page-controls span {
  font: inherit;
  font-size: .8em;
  line-height: 1.5;
  padding-top: 0.2em;
  padding: 0.2em 8px 0;
}

.react-pdf__Document {
  border-radius: 4px;
  max-width: 100%;
  position: relative;
  background-color: #fff;
  overflow: hidden
}

.rulog-document {
  width: 100%;
  margin: auto;

  .react-pdf__Page {
    width: 100%;
    height: auto;
  }

  .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
  }
}