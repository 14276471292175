@tailwind base;
@tailwind components;
@tailwind utilities;

.tabs {
  scrollbar-width: none;
}
.tabs::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Overpass', sans-serif;
}

.snackbar {
  box-shadow: 0px 4px 16px rgba(32, 33, 35, 0.04),
    0px 2px 4px rgba(32, 33, 35, 0.08);
}

a:focus-visible {
  outline: none;
  border: 1px solid #27282c;
}

.bg-black a:focus-visible,
footer a:focus-visible {
  outline: none;
  border: 1px solid #ffffff;
}

button:focus-visible,
input[type='checkbox']:focus-visible {
  outline: none;
  border: 1px solid #006cb6;
}

footer .btn__no-link {
  &:focus-visible {
    outline: 1px solid #fff;
    border: none;
  }
}

.marquee {
  align-items: center;
}

.marquee .initial-child-container {
  align-items: center;
}

/* Input styles */

.form-group {
  position: relative;
}

.form__field {
  transition: border-color 0.2s;
  outline: 0;
  font-size: 0.875rem;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 0.875rem;
  font-weight: 400;
  cursor: text;
  top: 19px;
  color: #90949d;
}

label,
.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  font-size: 0.75rem;
  display: block;
  transition: 0.2s;
  color: #90949d;
}

.dropdown {
  background-color: #ffffff;
  box-shadow: 0px 4px 16px rgba(32, 33, 35, 0.04),
    0px 2px 4px rgba(32, 33, 35, 0.08);
  z-index: 50;
  scrollbar-width: none;
}
.dropdown::-webkit-scrollbar {
  display: none;
}

.overlay {
  position: fixed;
  display: flex;
  top: 100;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(12, 7, 15, 0.24);
  z-index: 50;
  overflow-y: auto;
}

.overlayModal {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(12, 7, 15, 0.24);
  z-index: 50;
}

.listItem {
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
}

.listItem::before {
  content: '';
  width: 6px;
  height: 6px;
  background-color: rgb(255 255 255 / 0.8);
  border-radius: 50%;
  padding-inline: 3px;
  margin-inline-end: 12px;
  margin-top: 7px;
}
.close {
  position: relative;
  width: 36px;
  height: 32px;
}
.close:before,
.close:after {
  position: absolute;
  left: 100%;
  top: 100%;
  margin-left: -16px;
  margin-top: -25px;
  content: ' ';
  height: 20px;
  width: 2px;
  background-color: #ffffff;
}
.close:hover:before,
.close:hover:after {
  background-color: rgb(98 103 112 / 1);
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
.activeTextSlide {
  transform-origin: top left;
}
.textSlide {
  transform-origin: top left;
}
.textBottomSlide {
  transform-origin: bottom left;
}
.activeBottomTextSlide {
  transform-origin: bottom left;
}
.post-card::-webkit-scrollbar {
  width: 4px;
}
.post-card::-webkit-scrollbar-track {
  background: #f7f8fa;
  border-radius: 4px;
}
.post-card::-webkit-scrollbar-thumb {
  background-color: #dadde5;
  border-radius: 4px;
}

.markdown p {
  margin-bottom: 12px;
}

.markdown p:last-child {
  margin-bottom: 0;
}
@media (max-width: 940px) {
  .post-card::-webkit-scrollbar {
    display: none;
  }
}
