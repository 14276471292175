.react-tel-input {
  font-family: unset !important;
  font-size: 0.875rem !important;
  position: relative;
  padding-top: 0.5rem;
  width: 100%;
  .form-control {
    border: none !important;
    border-bottom: 1px solid rgb(72 74 78 / 1) !important;
    border-radius: 0px !important;
  }
  //   .errorInput {
  //     border-bottom: 1px solid red !important;
  //   }
  //   .errorInput:hover {
  //     border-bottom: 1px solid red !important;
  //   }
  //   .errorInput:focus {
  //     border-bottom: 1px solid red !important;
  //   }
  .special-label {
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    pointer-events: none;
    cursor: text !important;
    top: 10px !important;
    left: -1px !important;
    display: block !important;
    transition: 0.2s !important;
    color: #90949d !important;
  }

  &:focus-within > .special-label {
    display: block !important;
    position: absolute;
    top: -5px !important;
    font-size: 0.75rem !important;
    display: block !important;
    transition: 0.2s !important;
    color: #90949d !important;
  }
}

.container > .special-label {
  display: block !important;
  position: absolute;
  top: -5px !important;
  font-size: 0.75rem !important;
  display: block !important;
  transition: 0.2s !important;
  color: #90949d !important;
}

.errorContainer {
  .errorInput {
    border-bottom: 1px solid red !important;
  }
  .errorInput:hover {
    border-bottom: 1px solid red !important;
  }
  .errorInput:focus {
    border-bottom: 1px solid red !important;
  }
  &:focus-within > .special-label {
    color: red !important;
  }
  .special-label {
    color: red !important;
  }
}
