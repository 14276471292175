@keyframes expandSearch {
  from {
    overflow: hidden;
  }
  to {
    overflow: visible;
  }
}

.SearchContainer {
  animation: expandSearch 0.3s ease-in-out;
}
