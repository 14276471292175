.tbk {
  display: none;
  @media (min-width: theme('screens.mb')) and (max-width: theme('screens.dk')) {
    display: block;
  }
}

.dkk, .dkc, .dk {
  display: block;
  @media (max-width: theme('screens.dk')) {
    display: none;
  }
}

.mbc {
  display: none;
  @media (max-width: theme('screens.tb')) {
    display: block;
  }
}


.geo-main {
  .mbc, .tbk, .dkk, .dkc {
    display: none;
  }
}

.destr-main {
  .dk, .mbc, .tbk {
    display: none;
  }
}

.list-main {
  .dk, .dkk, .dkc, .tbk, {
    display: none;
  }
}